import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFirestore, serverTimestamp } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCZX18KYzbt5PnuVWkqQapMo6vzXPWGVLI",
  authDomain: "fylla-nu.firebaseapp.com",
  projectId: "fylla-nu",
  storageBucket: "fylla-nu.appspot.com",
  messagingSenderId: "239163065586",
  appId: "1:239163065586:web:ea1645f0bbfa015147fc6b",
  measurementId: "G-YTFQ1GWRN5"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

let uid;
signInAnonymously(auth)
  .then(credentials => {
    uid = credentials.user.uid;
  });

import { query, orderBy, doc, collection, addDoc, setDoc, getDoc, getDocs, deleteDoc, onSnapshot } from "firebase/firestore";
import { generateRoomCode, getRandomFromArray } from "./utils";

import { reactive } from 'vue';
let gameRoom = reactive({});

async function createRoom() {
  let roomSnap, roomCode;
  do {
    roomCode = generateRoomCode();
    roomSnap = await getDoc(doc(db, "Rooms", roomCode));
  } while (roomSnap.exists());

  await deleteRoom(roomCode);

  const roomRef = doc(db, "Rooms", roomCode);
  await setDoc(roomRef, {
    Host: uid,
    HostPing: serverTimestamp(),
  });
  
  await addDoc(collection(roomRef, "History"), {
    Question: "Svep upp för att starta spelet",
    Timestamp: serverTimestamp(),
  });

  joinRoom(roomCode);
}

async function deleteRoom(roomCode) {
  const roomRef = doc(db, "Rooms", roomCode);
  const roomSnap = await getDoc(roomRef);
  if (roomSnap.exists()) {
    // Clear all player questions
    const questionsSnap = await getDocs(collection(roomRef, "Questions"));
    questionsSnap.forEach(doc => {
      deleteDoc(doc.ref);
    });
    // Clear all previos history
    const historySnap = await getDocs(collection(roomRef, "History"));
    historySnap.forEach(doc => {
      deleteDoc(doc.ref);
    });
    deleteDoc(roomRef);
  }
}

async function addQuestion(text) {
  const roomRef = doc(db, "Rooms", gameRoom.roomCode);
  await addDoc(collection(roomRef, "Questions"), {
    Question: text
  });
}

async function nextQuestion() {
  while (! gameRoom.questionPool || gameRoom.questionPool.length < 5) {
    gameRoom.questionPool = gameRoom.questionPool || [];
    
    const playerMadeCount = gameRoom.questionPool.filter(q => q && q.isPlayerMade).length;
    const goodPlayerQuestions = gameRoom.playerQuestions.filter(question => {
      return ! gameRoom.questionPool.some(q => q.id == question.id)
    });
    if (goodPlayerQuestions.length && playerMadeCount < 4) {
      const playerQuestion = getRandomFromArray(goodPlayerQuestions);
      playerQuestion.isPlayerMade = true;

      gameRoom.questionPool.push(playerQuestion);
    } else {
      const goodStaticQuestions = gameRoom.staticQuestions.filter(question => {
        return ! gameRoom.history.some(h => h.id == question.id) && ! gameRoom.questionPool.some(q => q.id == question.id)
      });
      const staticQuestion = getRandomFromArray(goodStaticQuestions);

      gameRoom.questionPool.push(staticQuestion);
    }
  }

  gameRoom.questionPool = gameRoom.questionPool.filter(q => !! q);
  if (! gameRoom.questionPool.length) {
    gameRoom.questionPool.push({ id: "0", text: "Slut på frågor, skriv fler eller fixa runda shots till alla..." });
  }

  const question = getRandomFromArray(gameRoom.questionPool);
  if (question.isPlayerMade) {
    const questionRef = doc(db, "Rooms", gameRoom.roomCode, "Questions", question.id);
    await deleteDoc(questionRef);
  }

  const roomRef = doc(db, "Rooms", gameRoom.roomCode);
  await setDoc(doc(roomRef, "History", question.id), {
    Question: question.text,
    Timestamp: serverTimestamp(),
  });

  const index = gameRoom.questionPool.indexOf(question);
  if (index > -1) {
    gameRoom.questionPool.splice(index, 1);
  }
}

async function joinRoom(roomCode) {
  roomCode = [...roomCode].filter(c => c != '#').join('');
  if (! roomCode || roomCode.length !== 3) {
    return false;
  }

  const roomSnap = await getDoc(doc(db, "Rooms", roomCode));
  if (! roomSnap.exists()) {
    return false;
  }

  gameRoom.roomCode = roomCode;
  gameRoom.history = [];
  gameRoom.playerQuestions = [];
  gameRoom.staticQuestions = [];

  gameRoom.unsubscribe = [];

  gameRoom.unsubscribe[0] = await onSnapshot(query(collection(db, "Rooms", roomCode, "History"), orderBy("Timestamp")), historySnap => {
    let history = [];
    historySnap.forEach(doc => {
      history.push({ id: doc.id, text: doc.data().Question });
    });
    gameRoom.history = history;
  });
  gameRoom.unsubscribe[1] = await onSnapshot(collection(db, "Rooms", roomCode, "Questions"), playerQuestionsSnap => {
    let playerQuestions = [];
    playerQuestionsSnap.forEach(doc => {
      playerQuestions.push({ id: doc.id, text: doc.data().Question });
    });
    gameRoom.playerQuestions = playerQuestions;
  });
  gameRoom.unsubscribe[2] = await onSnapshot(collection(db, "StaticQuestions"), staticQuestionsSnap => {
    let staticQuestions = [];
    staticQuestionsSnap.forEach(doc => {
      staticQuestions.push({ id: doc.id, text: doc.data().Question });
    });
    gameRoom.staticQuestions = staticQuestions;
  });
  
  return () => { gameRoom.unsubscribe.forEach(unsub => unsub()); }
}

export {
  gameRoom,
  joinRoom,
  createRoom,
  addQuestion,
  nextQuestion
}