function generateRoomCode() {
    let roomCode = '';
    for (let i = 0; i < 3; i++) {
        const min = 65; // char A
        const max = 90; // char Z
        let char = Math.random() * (max - min) + min;
        roomCode += String.fromCharCode(char);
    }
    return roomCode;
}

function getRandomFromArray(array) {
    const random = Math.floor(Math.random() * array.length);
    return array[random];
}

export {
    generateRoomCode,
    getRandomFromArray,
}