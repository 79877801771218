<template>
  <canvas id="wave" ref="wave"></canvas>
  <template v-if="! room.roomCode">
    <div class="home">
      <div class="righty nav">
        <div class="hamburger">
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </div>
      <div class="lefty">
        <h1 class="header">Fylla<span class="highlight">.nu</span></h1>
      </div>

      <div class="menu" v-if="! loading">
        <div class="maxxy">
          <button class="btn" @click="createRoom">Starta nytt spel</button>
        </div>
        <div class="joiny mt-3">
          <input class="inp" :class="{ 'warn': !! errorMessage }" v-model="roomCode" maxlength="4" placeholder="#AAA" type="text"/>
          <button class="btn" @click="joinRoom">Delta</button>
        </div>
        <div class="centy mt-1">
          <span class="error">{{ errorMessage }}</span>
        </div>
      </div>
      <div class="menu" v-else>
        <span class="spinner"></span>
      </div>
    </div>
    <div class="liquid"></div>
  </template>
  <template v-else>
    <div class="home">
      <div class="splity">
        <p class="small">Du är i rum:</p>
        <button class="link" @click="backToMenu">Lämna rum</button>
      </div>
      <div class="lefty">
        <p class="label highlight">{{ room.roomCode }}</p>
      </div>
      <div class="container">
        <div class="maxxy">
          <p class="label">{{ currentQuestion }}</p>
        </div>
      </div>
      <div class="centy mb-3">
        <button class="link" @click="writeQuestion">Lägg till en anonym fråga</button>
      </div>
    </div>
    <div class="liquid">
      <div class="centy">
        <p class="small" @click="nextQuestion">Svep upp för nästa fråga</p>
      </div>
    </div>
  </template>
</template>

<script>
import { gameRoom, createRoom, joinRoom, addQuestion, nextQuestion } from "./db";
export default {
  name: "App",
  data() {
    return {
      room: gameRoom,
      roomCode: "",
      loading: false,
      errorMessage: "",
      leaveRoom: null,
      wave: null,
    }
  },
  methods: {
    backToMenu() {
      if (this.leaveRoom) {
        this.leaveRoom();
      }
      this.roomCode = "";
      this.room.roomCode = "";
      this.errorMessage = "";
      this.loading = false;
    },
    async createRoom() {
      this.loading = true;
      await createRoom();
    },
    async joinRoom() {
      this.loading = true;
      this.leaveRoom = await joinRoom(this.roomCode);
      this.loading = false;
      if (! this.leaveRoom) {
        this.errorMessage = "Det här rummet finns inte!";
      }
    },
    writeQuestion() {
      const question = prompt("Fråga", "hej");
      addQuestion(question);
    },
    async nextQuestion() {
      nextQuestion();
    }
  },
  computed: {
    currentQuestion() {
      const lastQuestion = [...this.room.history].pop();
      return lastQuestion ? lastQuestion.text : "";
    }
  },
  watch: {
    roomCode(val) {
      this.roomCode = val.toUpperCase().trim();
      if (this.roomCode.length > 0 && this.roomCode[0] !== '#') {
        this.roomCode = '#' + this.roomCode;
      }
      if (this.roomCode.length == 1 && this.roomCode[0] === '#') {
        this.roomCode = "";
      }
    }
  },
  mounted() {
    this.wave = this.$refs.wave;
    let ctx = this.wave.getContext("2d");

    this.wave.width = window.innerWidth;
    this.wave.height = window.innerHeight;

    ctx.fillStyle = "#FF2281";
    ctx.beginPath();
    ctx.moveTo(0, this.wave.height*0.7);
    for (var i=0; i<this.wave.width; i++) {
      let y = this.wave.height*0.7;
      let amp = 20;
      ctx.lineTo(i, y+(Math.cos(i/30)*amp) + amp);
    }
    ctx.lineTo(this.wave.width, this.wave.height);
    ctx.lineTo(0, this.wave.height);
    ctx.closePath();
    ctx.fill();
  }
}
</script>
